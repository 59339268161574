<template>
  <LSection>
    <template #body>
      <header class="heading1 !mb-2">
        {{ title }}
        <hr class="gradientLine" />
      </header>
      <div class="flex gap-4 py-8">
        <p>{{ description }}</p>
        <img
          :src="imgSrc"
          :alt="title"
          class="rounded-full w-96 aspect-square object-cover"
        />
      </div>
      Replace this with all projects from the specific area
      <CardsNews></CardsNews>
    </template>
  </LSection>
</template>

<script>
import { computed } from "vue";
import LSection from "@/components/layout/LSection.vue";
import { impactMap } from "@/views/Impact/Data/impactMap";
import CardsNews from "@/components/News/CardsNews.vue";

export default {
  name: "ImpactDetails",
  components: { CardsNews, LSection },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const imgSrc = computed(() => {
      const impact = impactMap.find((item) => item.title === props.title);
      return impact ? impact.img : null;
    });
    return {
      imgSrc,
    };
  },
};
</script>

<style scoped></style>
