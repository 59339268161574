import HealthMedicine from "@/assets/images/Impact/Health&Medicine.png";
import EnvironmentalSustainability from "@/assets/images/Impact/EnviromentalSustainability.png";
import ManufacturingIndustry from "@/assets/images/Impact/Manufacturing&Industry.png";
import CommunicationNetworking from "@/assets/images/Impact/Communication&Neworking.png";
import SecurityDefence from "@/assets/images/Impact/Security&Defence.png";
import SpaceExplorationResearch from "@/assets/images/Impact/SpaceExploration&Research.png";
import TransportInfrastructure from "@/assets/images/Impact/Transdport&Infrastructure.png";
import FinanceEconomics from "@/assets/images/Impact/Finance&Economics.png";

export const impactMap = [
  {
    img: HealthMedicine,
    title: "Health & Medicine",
    navigation: "health-medicine",
  },
  {
    img: EnvironmentalSustainability,
    title: "Environmental Sustainability",
    navigation: "environmental-sustainability",
  },

  {
    img: ManufacturingIndustry,
    title: "Manufacturing & Industry",
    navigation: "manufacturing-industry",
  },
  {
    img: CommunicationNetworking,
    title: "Communication & Networking",
    navigation: "communication-networking",
  },
  {
    img: SecurityDefence,
    title: "Security & Defence",
    navigation: "security-defence",
  },
  {
    img: SpaceExplorationResearch,
    title: "Space Exploration & Research",
    navigation: "space-exploration-research",
  },
  {
    img: TransportInfrastructure,
    title: "Transport & Infrastructure",
    navigation: "transport-infrastructure",
  },
  {
    img: FinanceEconomics,
    title: "Finance & Economics",
    navigation: "finance-economics",
  },
];
